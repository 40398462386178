import { createIntl, createIntlCache } from 'react-intl';
import _ from 'lodash';

const supportedLocales = [
  'ar',
  'bg',
  'cs',
  'cy',
  'da',
  'de',
  'en-us',
  'en-gb',
  'es',
  'es-mx',
  'fi',
  'fr',
  'fr-ca',
  'he',
  'is',
  'it',
  'ja',
  'ko',
  'nb',
  'nl',
  'no',
  'nn-no',
  'pl',
  'pt-br',
  'pt-pt',
  'ru',
  'sv',
  'th',
  'tr',
  'zh-cn',
  'zh-tw'
];

export function getUserLocale() {
  let locale = [];

  const queryLocale = getQueryLocale();
  if (queryLocale) {
    locale.push(queryLocale.toLocaleLowerCase());
  } else if (navigator.languages) {
    locale.push(navigator.languages[0].toLocaleLowerCase());
  } else if (navigator.language) {
    locale.push(navigator.language.toLocaleLowerCase());
  } else {
    locale.push('en-us');
  }

  return locale[0];
}

const userLocale = getUserLocale();

async function loadTranslations() {
  let translations;

  if (_.includes(supportedLocales, userLocale)) {
    translations = await import(`../translations/${userLocale}/strings.json`);
  } else {
    translations = await import(`../translations/en-us/strings.json`);
  }

  return translations;
}

loadTranslations().then((translatedStrings: any) => {
  sessionStorage.setItem(
    'translatedStrings',
    JSON.stringify(translatedStrings.default)
  );
});

let storedTranslations = sessionStorage.getItem('translatedStrings');

export let messages: any = storedTranslations
  ? JSON.parse(storedTranslations)
  : {};

const cache = createIntlCache();
let int = createIntl(
  {
    locale: userLocale,
    messages: messages
  },
  cache
);

export async function getMessages(): Promise<any>
{
  if (!!storedTranslations)
  {
    return JSON.parse(storedTranslations);
  }
  console.log("Translation not cached, loading...");
  await loadTranslations();

  // Load the translations into the storedTranslations variable again.
  storedTranslations = sessionStorage.getItem('translatedStrings');

  if (!storedTranslations)
  {
    console.error("Unable to load translations");
    return {};
  }

  messages = JSON.parse(storedTranslations);
  int = createIntl(
    {
      locale: userLocale,
      messages: messages
    },
    cache
  );

  return messages;
}

export const translate = (id: string, values?: {}) => {
  return int.formatMessage({ id }, values);
};

function getQueryLocale() :string | null {
  const url = new URL(document.location.href);
  let queryLocale = url.searchParams.get('locale');

  if (!queryLocale) {
    return null;
  }

  queryLocale = queryLocale.toLocaleLowerCase();

  if (_.includes(supportedLocales, queryLocale)) {
    return queryLocale;
  }

  const parts = queryLocale.split('-');
  if (parts && parts.length > 0 && _.includes(supportedLocales, parts[0])) {
    return parts[0];
  }
  return null;
}

// Normalizes a tag like fr-ca to fr-CA
function normalizeBcp47(tag: string | null) {
  if (!tag) {
    return tag;
  }
  let upperTag = tag.toLocaleUpperCase();
  const parts = upperTag.split('-');
  const language = parts[0].toLocaleLowerCase();
  if (parts.length === 1) {
    return language;
  } else {
    return `${language}-${parts[1]}`
  }
}

// fr-CA,fr;q=0.9,en-CA;q=0.8,en;q=0.7,af;q=0.6,en-GB;q=0.5,en-US;q=0.4
export function getAcceptedLanguagesHeader() {
  let locale = [];

  let queryLocale = getQueryLocale();
  if (queryLocale) {
    queryLocale = normalizeBcp47(queryLocale);
    locale.push(queryLocale);
  }

  if (navigator.languages) {
    locale.push(...navigator.languages);
  } else if (navigator.language) {
    locale.push(navigator.language);
  } else {
    locale.push('en-US');
  }

  let weight = 1.0
  return locale.reduce((accumulator, tag) => {
    weight -= 0.001;
    return `${accumulator},${tag};q=${weight}`;
  });
}