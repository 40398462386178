import React from 'react';
import { Stack, Text, DefaultButton, PrimaryButton, Link } from 'office-ui-fabric-react';
import { AppState } from './RootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { OnlineMeeting } from './meeting-creator/models';
import { Header } from './components/header';
import { FormattedMessage } from 'react-intl';
import { translate } from './localization/translate';

import {
  SET_MEETING_COMMAND,
  SetMeetingCommand
} from './meeting-creator/actions';

import { push } from 'connected-react-router';
import { createDefaultMeetingInput } from './meeting-creator/models';

const datePickerFormat = 'll';
const timePickerFormat = 'LT';

interface CopyMeetingPageProps {
  meeting?: OnlineMeeting;
  onCopyToClipboard: (meeting?: OnlineMeeting) => void;
  onNewMeeting: () => void;
  onInsert: (returnUrlParameter?: string | null, meeting?: OnlineMeeting) => void;
  returnUrlParameter?: string | null
}

const mapStateToProps = (state: AppState) => {
  const url = new URL(document.location.href);
  let returnUrlParameter = url.searchParams.get('returnUrl');
  return {
    meeting: state.meeting.createdMeeting,
    returnUrlParameter
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onCopyToClipboard: (meeting?: OnlineMeeting) => {
    // const str =
    //   document.getElementById('copy')?.innerHTML ||
    //   translate('copyMeetingPage.failed.copy');

    // Safari copy event only fires when there is a selection so force a selection of some
    // invisible text. Forcing invisible using height/width.
    // display: none and visibility: hidden do not work.
    selectElementContents(document.getElementById('copyBlank'));

    function listener(e : ClipboardEvent) {
      if (!e || !e.clipboardData) {
        return
      }
      // Don't copy HTML for now as it breaks when pasting into Activity Feed HTML editor
      // e.clipboardData.setData("text/html", str);
      e.clipboardData.setData(
        'text/plain',
        meeting?.joinWebUrl ?? translate('copyMeetingPage.failed.copy')
      );
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  },

  onNewMeeting:() => {
    dispatch({
      type: SET_MEETING_COMMAND,
      meeting: createDefaultMeetingInput()
    } as SetMeetingCommand);
    dispatch(push('/createMeeting'));
  },

  onInsert: (returnUrlParameter?: string | null, meeting?: OnlineMeeting) => {
    if (meeting && returnUrlParameter) {
      let returnUrl = new URL(returnUrlParameter);
      let returnUrlSearchParams = returnUrl.searchParams;
      returnUrlSearchParams.set('quickLink', meeting.joinWebUrl);
      returnUrlSearchParams.set('title', meeting.subject);
      returnUrlSearchParams.set('target', 'NewWindow');
      returnUrl.search = returnUrlSearchParams.toString();

      document.location.href = returnUrl.toString();
    }
  }
});

function selectElementContents(el: any) {
  let range = document.createRange();
  range.selectNodeContents(el);
  let sel = window.getSelection();
  if (sel) {
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

function insertButton(props: CopyMeetingPageProps) {
  return (
    <Stack
      verticalAlign="stretch"
      horizontalAlign="stretch"
      verticalFill
      tokens={{
        childrenGap: 12
      }}>
      <PrimaryButton
        className="teamsButton d2lCopyButton"
        onClick={() => props.onInsert(props.returnUrlParameter, props.meeting)}
        ariaLabel={translate('copyMeetingPage.insert')}
      >
        <FormattedMessage id="copyMeetingPage.insert" />
      </PrimaryButton>
    </Stack>
  );
}

function copyButtons(props: CopyMeetingPageProps) {
  return (
    <Stack
      verticalAlign="stretch"
      horizontalAlign="stretch"
      verticalFill
      tokens={{
        childrenGap: 12
      }}>
          <PrimaryButton
            className="teamsButton d2lCopyButton"
            onClick={() => props.onCopyToClipboard(props.meeting)}
            ariaLabel={translate('copyMeetingPage.copy.ariaLabel')}
          >
            <FormattedMessage id="copyMeetingPage.copy.ariaLabel" />
          </PrimaryButton>
          <DefaultButton
            className="teamsButtonInverted d2lNewMeetingButton"
            onClick={() => props.onNewMeeting()}
            ariaLabel={translate('meetingPage.cancel.ariaLabel')}
          >
            <FormattedMessage id="meetingPage.header" />
          </DefaultButton>
    </Stack>
  );
}

function CopyMeetingPageComponent(props: CopyMeetingPageProps) {
  return (
    <>
      <Header />
      <Stack
        className="container"
        verticalFill
        tokens={{
          childrenGap: 35
        }}
      >
        <Stack.Item align="center" className="meetingCardContainer">
          <svg
            className="meetingSuccess"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
          >
            <path
              d="M24 0c2.2 0 4.3.3 6.4.9 2 .6 3.9 1.4 5.7 2.4 1.8 1 3.4 2.3 4.9 3.8 1.5 1.5 2.7 3.1 3.8 4.9 1 1.8 1.8 3.7 2.4 5.7.6 2 .9 4.2.9 6.4s-.3 4.3-.9 6.3c-.6 2-1.4 3.9-2.4 5.7-1 1.8-2.3 3.4-3.8 4.9-1.5 1.5-3.1 2.7-4.9 3.8-1.8 1-3.7 1.9-5.7 2.4-2 .6-4.1.9-6.4.9-2.2 0-4.3-.3-6.3-.9-2-.6-3.9-1.4-5.7-2.4-1.8-1-3.4-2.3-4.9-3.8-1.5-1.5-2.7-3.1-3.8-4.9-1-1.8-1.9-3.7-2.4-5.7C.3 28.3 0 26.2 0 24s.3-4.3.9-6.4c.6-2 1.4-3.9 2.4-5.7 1-1.8 2.3-3.4 3.8-4.9 1.5-1.5 3.1-2.7 4.9-3.8 1.8-1 3.7-1.9 5.7-2.4S21.8 0 24 0zm7.9 17.1c-.6 0-1.2.2-1.6.7l-8.5 8.5-3-3c-.4-.4-1-.7-1.6-.7-.3 0-.6.1-.8.2-.3.1-.5.3-.7.5s-.4.4-.5.7c-.2.3-.2.5-.2.8 0 .6.2 1.2.7 1.6l4.6 4.6c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7l10.1-10.1c.4-.5.7-1 .7-1.6 0-.3-.1-.6-.2-.8-.1-.3-.3-.5-.5-.7s-.4-.4-.7-.5c-.4-.2-.7-.2-1-.2z"
              fill="#599c00"
            />
          </svg>

          <Text block variant="xLarge" className="meetingCardHeader">
            <FormattedMessage id="copyMeetingPage.header" />
          </Text>
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            verticalFill
            tokens={{
              childrenGap: 4
            }}
          >
            <Link href={props.meeting?.joinWebUrl} className="meetingLink" target="_blank">
              {props.meeting?.subject}
            </Link>

            <Text variant="medium">{props.meeting?.startDateTime?.format(datePickerFormat)}</Text>
            <Stack.Item >
              <Text variant="medium">{props.meeting?.startDateTime?.format(timePickerFormat)}</Text>
              <Text variant="medium">&nbsp;&ndash;&nbsp;</Text>
              <Text variant="medium">{props.meeting?.endDateTime?.format(timePickerFormat)}</Text>
            </Stack.Item>

            <Stack.Item>
              {props.returnUrlParameter ? insertButton(props) : copyButtons(props)}
            </Stack.Item>

          </Stack>
          <div
            className="meetingCardBody"
            id="copy"
            dangerouslySetInnerHTML={{ __html: props.meeting?.preview ?? '' }}
          />
          <div id="copyBlank" aria-hidden="true" style={{ height: 0, width: 0, overflow: "hidden" }}>Safari copy</div>

        </Stack.Item>
      </Stack>
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyMeetingPageComponent);
